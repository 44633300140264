import { atom } from "recoil";

const headCells = [
  { id: "client", numeric: false, label: "Client", active: true },
  { id: "poste", numeric: false, label: "Poste", active: true },
  { id: "shortList", numeric: false, label: "Candidat", active: true },
  { id: "inCharge", numeric: false, label: "Abberliner IN", active: true },
  { id: "outCharge", numeric: false, label: "Abberliner.s OUT", active: true },
  { id: "dateRetour", numeric: false, label: "Date", active: true },
  { id: "rem", numeric: false, label: "Honoraires", active: true },
];

function parse(type) {
  return typeof type == "string" ? JSON.parse(type) : type;
}

const colsTableAtom = atom({
  key: "cols",
  default: localStorage.getItem("cols") || headCells,
});

const clientAtom = atom({
  key: "client",
  default: parse(localStorage.getItem("client")) ?? true,
});
const posteAtom = atom({
  key: "poste",
  default: parse(localStorage.getItem("poste")) ?? true,
});
const shortListAtom = atom({
  key: "shortList",
  default: parse(localStorage.getItem("shortList")) ?? true,
});
const inChargeAtom = atom({
  key: "inCharge",
  default: parse(localStorage.getItem("inCharge")) ?? true,
});
const outChargeAtom = atom({
  key: "outCharge",
  default: parse(localStorage.getItem("outCharge")) ?? true,
});
const dateRetourAtom = atom({
  key: "dateRetour",
  default: parse(localStorage.getItem("dateRetour")) ?? true,
});
const remAtom = atom({
  key: "rem",
  default: parse(localStorage.getItem("rem")) ?? true,
});

export {
  colsTableAtom,
  clientAtom,
  posteAtom,
  shortListAtom,
  inChargeAtom,
  outChargeAtom,
  dateRetourAtom,
  remAtom,
};
