import React, { useEffect, useState } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useFetchWrapper } from "../helpers/axiosWrapper";
import {
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { COLORS, INITIALS } from "../helpers/defs";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  table: {
    padding: theme.spacing(0, 0),
  },
  1: {
    backgroundColor: `${COLORS.green}`,
  },
  2: {
    backgroundColor: `${COLORS.yellow}`,
    color: "black",
  },
  3: {
    backgroundColor: `${COLORS.orange}`,
  },
  4: {
    backgroundColor: `${COLORS.red}`,
  },
  cell: {
    color: "inherit",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "inCharge", numeric: false, label: "Abberliner" },
  { id: "client", numeric: false, label: "Client" },
  { id: "rdv", numeric: false, label: "RDV" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const Rdv = () => {
  const fetchWrapper = useFetchWrapper();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("client");
  const [selected, setSelected] = React.useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const dense = localStorage.getItem("dense") || false;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [commentaireDialog, setCommentaireDialog] = useState("");
  const [page, setPage] = React.useState(0);
  const [searchRes, setSearchRes] = useState(detailsData);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value, id) => {
    setCommentaireDialog(value);
    setOpen(true);
  };

  const handleClose = () => {
    setCommentaireDialog("");
    setOpen(false);
  };

  const getRdv = async () => {
    fetchWrapper.get(`/kpi/chasse/rdv`).then((res) => {
      const rows = res.data;
      setDetailsData(rows);
    });
  };

  useEffect(() => {
    getRdv();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = detailsData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSearchRes(detailsData);
  }, [detailsData]);

  const classes = useStyles();

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, detailsData.length - page * rowsPerPage);

  if (detailsData.length > 0) {
    return (
        <>
        <TableContainer component={Paper} className={classes.table}>
          <Table size={dense ? "small" : "medium"} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={detailsData.length}
            />
            <TableBody>
              {detailsData.length > 0 &&
                stableSort(searchRes, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        onClick={
                          row.commentaire
                            ? () => handleClickOpen(row.commentaire, row.id)
                            : null
                        }
                        key={row.id}
                        className={classes[row.diff]}
                      >
                        <TableCell
                          style={
                            !row.shortList
                              ? { border: "none" }
                              : {
                                  borderColor: "#6DBCE8",
                                  borderWidth: "0px 0 0px 5px",
                                }
                          }
                          className={classes.cell}
                          scope="row"
                          align={"left"}
                        >
                          {row.commentaire ? (
                            <Box whiteSpace="nowrap">
                              <Badge
                                color="secondary"
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                variant="dot"
                              >
                                {INITIALS[row.inCharge] ? (
                                  <Avatar
                                    style={{
                                      width: 25,
                                      height: 25,
                                      fontSize: 14,
                                    }}
                                  >
                                    {INITIALS[row.inCharge]}
                                  </Avatar>
                                ) : (
                                  <Typography>{row.inCharge}</Typography>
                                )}
                              </Badge>
                            </Box>
                          ) : (
                            <Box whiteSpace="nowrap">
                              <Typography>
                                {INITIALS[row.inCharge] ? (
                                  <Avatar
                                    style={{
                                      width: 25,
                                      height: 25,
                                      fontSize: 14,
                                    }}
                                  >
                                    {INITIALS[row.inCharge]}
                                  </Avatar>
                                ) : (
                                  <Typography>{row.inCharge}</Typography>
                                )}
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className={classes.cell}
                          align="left"
                        >
                          <Box whiteSpace="nowrap">{row.client}</Box>
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className={classes.cell}
                          align="left"
                        >
                          <Box whiteSpace="nowrap">{row.dateRdv}</Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell style={{ borderBottom: "none" }} colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Lignes/page"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={detailsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Commentaire"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {commentaireDialog}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} color="primary">
              Fermer
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              color="primary"
            >
              Complete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};
