import { AppBar, Box, Fab, IconButton, SwipeableDrawer, Switch, Toolbar } from '@material-ui/core';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DetailsIcon from "@material-ui/icons/Details";
import AddIcon from "@material-ui/icons/Add";
import EventIcon from "@material-ui/icons/Event";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import { yellow } from '@material-ui/core/colors';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useRecoilState } from 'recoil'
import { authAtom } from '../state/auth';
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
    padding: theme.spacing(2) + theme.spacing(4),
    justifyContent: "space-between",
    display: "flex",
  },
  list: {
    marginBottom: theme.spacing(2),

    width: 250,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -70,
    right: 10,
    margin: "0 auto",
  },
  fullList: {
    width: "auto",
  },
  customBadge: {
    padding: theme.spacing(2),
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    fontWeight: "600",
    fontSize: 20,
    color: "white",
  },
  badgeGreen: {
    backgroundColor: `${theme.palette.success.dark}`,
  },
  badgeYellow: {
    backgroundColor: `${theme.palette.warning.dark}`,
  },
  badgeRed: {
    backgroundColor: `${theme.palette.error.main}`,
  },
  badgeDanger: {
    backgroundColor: `#801313`,
  },
  growContainer: {
    flexGrow: 1,
  },
}));

export const Appbar = ({ path, dark, setDark }) => {
  const classes = useStyles();
  const [auth, setAuth] = useRecoilState(authAtom);
  const [themeSwitch, setThemeSwitch] = useState(localStorage.getItem('theme') || false);

  const handleThemeSwitch = (event) => {
    localStorage.setItem("theme", event.target.checked ? true : '');
    setThemeSwitch(event.target.checked);
    setDark(event.target.checked);
  };

  const handleLogout = () => {
    setState({ left: false })
    localStorage.removeItem('jwt');
    setAuth(null);
  }
  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            {themeSwitch ? (
              <WbSunnyIcon style={{ color: yellow[900] }} />
            ) : (
              <Brightness3Icon style={{ color: yellow[200] }} />
            )}{" "}
          </ListItemIcon>
          <ListItemText>
            Lights{" "}
            <Switch
              checked={themeSwitch}
              onChange={handleThemeSwitch}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </ListItemText>
        </ListItem>
        <Link to="/abberliners">
          <ListItem onClick={toggleDrawer(anchor, false)}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText>Abberliners</ListItemText>
          </ListItem>
        </Link>
        <Link to="/settings">
          <ListItem onClick={toggleDrawer(anchor, false)}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </ListItem>
        </Link>
        <Link to="">
          <ListItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  if (auth) {

    return (
      <Box>
        <>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
          <AppBar
            position="fixed"
            color={dark ? "default" : "default"}
            className={classes.appBar}
          >
            <Toolbar>
              <Link to={path === "/rdv" ? "/createRdv" : "/create"}>
                <Fab
                  disabled={path === "/create" || path === "/createRdv" ? true : false}
                  color="secondary"
                  aria-label="add"
                  className={classes.fabButton}
                >
                  <AddIcon />
                </Fab>
              </Link>
              <Box
                display="flex"
                justifyContent="space-between"
                flexGrow="1"
                m={1}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer("left", true)}
                >
                  <MenuIcon />
                </IconButton>
                <Link to="/dashboard">
                  <Button
                    variant={
                      path === "/dashboard" || path === "/" ? "contained" : "text"
                    }
                  >
                    <Box textAlign="center">
                      <DashboardIcon />
                      <Typography variant="body2">Dashboard</Typography>
                    </Box>
                  </Button>
                </Link>
                <Link to="/details">
                  <Button
                    variant={path === "/details" ? "contained" : "text"}
                    disableElevation
                  >
                    <Box textAlign="center">
                      <DetailsIcon />
                      <Typography variant="body2">Details</Typography>
                    </Box>
                  </Button>
                </Link>
                <Link to="/rdv">
                  <Button
                    variant={path === "/rdv" ? "contained" : "text"}
                    disableElevation
                  >
                    <Box textAlign="center">
                      <EventIcon />
                      <Typography variant="body2">RDV</Typography>
                    </Box>
                  </Button>
                </Link>
              </Box>
            </Toolbar>
          </AppBar>
        </>
      </Box>
    );
  } else {
    return null;
  }
};
