import { atom } from "recoil";

const messagesAtom = atom({
  key: "messages",
  default: null,
});

const moduleNotFound = atom({
  key: 'notFoundModule',
  default: false,
})

export { messagesAtom, moduleNotFound };
