import React, { useEffect, useState } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { sumBy, find, countBy } from "lodash";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  FormControlLabel,
  Switch,
  TableSortLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { COLORS, INITIALS } from "../helpers/defs";
import TablePagination from "@material-ui/core/TablePagination";
import { DialogChoice } from "../components/DialogChoice";
import { useRecoilState, useRecoilValue } from "recoil";
import { linesPerPageAtom } from "../state/config";
import { clientAtom, dateRetourAtom, inChargeAtom, outChargeAtom, posteAtom, remAtom, shortListAtom } from "../state/configTable";

const useStyles = makeStyles((theme) => ({
  table: {
    padding: theme.spacing(0, 0),
  },
  1: {
    backgroundColor: `${COLORS.green}`,
  },
  2: {
    backgroundColor: `${COLORS.yellow}`,
    color: "black",
  },
  3: {
    backgroundColor: `${COLORS.orange}`,
  },
  4: {
    backgroundColor: `${COLORS.red}`,
  },
  cell: {
    color: "inherit",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



export const Tableau = ({ data, setUpdateNeeded }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("client");
  const [selected, setSelected] = React.useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [dense, setDense] = React.useState(
    localStorage.getItem("dense") || false
  );
  const [rowsPerPage, setRowsPerPage] = useRecoilState(linesPerPageAtom);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState("");
  const [searchRes, setSearchRes] = useState(detailsData);
  const [choiseDialogStatus, setChoiseDialogStatus] = useState(false);
  const [choiseDialogData, setChoiseDialogData] = useState(null);
  const [totalRestant, setTotalRestant] = useState(0);

  const clientCol = useRecoilValue(clientAtom);
  const posteCol = useRecoilValue(posteAtom);
  const shortListCol = useRecoilValue(shortListAtom);
  const inChargeCol = useRecoilValue(inChargeAtom);
  const outChargeCol = useRecoilValue(outChargeAtom);
  const dateRetourCol = useRecoilValue(dateRetourAtom);
  const remCol = useRecoilValue(remAtom);

  const headCells = [
    clientCol && { id: "client", numeric: false, label: "Client", active: true },
    posteCol && { id: "poste", numeric: false, label: "Poste", active: true },
    shortListCol && { id: "shortList", numeric: false, label: "Candidat", active: true },
    inChargeCol && { id: "inCharge", numeric: false, label: "Abberliner IN", active: true },
    outChargeCol && {
      id: "outCharge",
      numeric: false,
      label: "Abberliner.s OUT",
      active: true,
    },
    dateRetourCol && { id: "dateRetour", numeric: false, label: "Date", active: true },
    remCol && { id: "rem", numeric: false, label: "Honoraires", active: true },
  ];


  const cols = countBy(headCells, { active: true })?.true;

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(
            (headCell) =>
              headCell.active && (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Box whiteSpace="nowrap">
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </Box>
                  </TableSortLabel>
                </TableCell>
              )
          )}
        </TableRow>
      </TableHead>
    );
  }

  const handleChoiseDialog = (data) => {
    setChoiseDialogData(data);
    setChoiseDialogStatus(true);
  };

  useEffect(() => {
    let remTotal = 0;
    for (let x in data) {
      if (!data[x].shortList) {
        remTotal = remTotal + data[x].rem * 0.6666;
        data[x].remNet = data[x].rem * 0.6666;
      } else {
        remTotal = remTotal + data[x].rem * 0.3333;
        data[x].remNet = data[x].rem * 0.3333;
      }
    }
    setTotalRestant(remTotal);
    console.log(totalRestant);
    console.log(sumBy(data, "rem"));
    setDetailsData(data);
    console.log(detailsData);
  }, [data]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value.length > 2) {
      setSearchRes(
        detailsData.filter((res) => {
          console.log("resresres", res);
          let resClient = res.client.toLowerCase();
          let resInCharge = res.inCharge.toLowerCase();
          let resPoste = res.poste.toLowerCase();
          return (
            resClient.indexOf(event.target.value.toLowerCase()) !== -1 ||
            resInCharge.indexOf(event.target.value.toLowerCase()) !== -1 ||
            resPoste.indexOf(event.target.value.toLowerCase()) !== -1
          );
        })
      );
    } else {
      setSearchRes(detailsData);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = detailsData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("linesPerPage", parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    localStorage.setItem("dense", event.target.checked ? true : "");
    setDense(event.target.checked);
  };

  useEffect(() => {
    setSearchRes(detailsData);
  }, [detailsData]);

  const classes = useStyles();

  if (data.length > 0) {
    return (
      <>
        <TableContainer component={Paper} className={classes.table}>
          <Table size={dense ? "small" : "medium"} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={detailsData.length}
            />
            <TableBody>
              {detailsData.length > 0 &&
                stableSort(searchRes, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        key={row.id}
                        className={classes[row.diff]}
                        onClick={() => handleChoiseDialog(row)}
                      >
                        {find(headCells, { id: "client" })?.active && (
                          <TableCell
                            style={
                              !row.shortList
                                ? { border: "none" }
                                : {
                                    borderColor: "#3f51b5",
                                    borderWidth: "0px 5px 0 0px",
                                  }
                            }
                            className={classes.cell}
                            scope="row"
                            align={"left"}
                          >
                            <Box whiteSpace="nowrap">
                              {row.client.toUpperCase()}
                            </Box>
                          </TableCell>
                        )}
                        {find(headCells, { id: "poste" })?.active && (
                          <TableCell
                            style={{ border: "none" }}
                            className={classes.cell}
                            align="left"
                          >
                            <Box whiteSpace="nowrap">{row.poste}</Box>
                          </TableCell>
                        )}
                        {find(headCells, { id: "shortList" })?.active && (
                          <TableCell
                            style={{ border: "none" }}
                            className={classes.cell}
                            align="left"
                          >
                            <Box whiteSpace="nowrap">{row.shortList}</Box>
                          </TableCell>
                        )}
                        {find(headCells, { id: "inCharge" })?.active && (
                          <TableCell
                            style={{ border: "none" }}
                            className={classes.cell}
                            align="left"
                          >
                            <Box whiteSpace="nowrap">
                              {INITIALS[row.inCharge] ? (
                                <Avatar
                                  style={{
                                    width: 25,
                                    height: 25,
                                    fontSize: 14,
                                  }}
                                >
                                  {INITIALS[row.inCharge]}
                                </Avatar>
                              ) : (
                                row.inCharge
                              )}
                            </Box>
                          </TableCell>
                        )}
                        {find(headCells, { id: "outCharge" })?.active && (
                          <TableCell
                            style={{ border: "none" }}
                            className={classes.cell}
                            align="left"
                          >
                            <Box whiteSpace="nowrap">
                              {INITIALS[row.outCharge] ? (
                                <Avatar
                                  style={{
                                    width: 25,
                                    height: 25,
                                    fontSize: 14,
                                  }}
                                >
                                  {INITIALS[row.outCharge]}
                                </Avatar>
                              ) : (
                                row.outCharge
                              )}
                            </Box>
                          </TableCell>
                        )}
                        {find(headCells, { id: "dateRetour" })?.active && (
                          <TableCell
                            style={{ border: "none" }}
                            className={classes.cell}
                            align="left"
                          >
                            <Box whiteSpace="nowrap">{row.dateRetour}</Box>
                          </TableCell>
                        )}
                        {find(headCells, { id: "rem" })?.active && (
                          <TableCell
                            style={{ border: "none" }}
                            className={classes.cell}
                            align="left"
                          >
                            <Box whiteSpace="nowrap">
                              {row.rem
                                ? new Intl.NumberFormat("fr-FR", {
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }).format(row.rem)
                                : ""}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              <TableRow>
                <TableCell colSpan={cols-2} />
                <TableCell colSpan={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography variant="h6">Restant</Typography>
                    <Typography variant="h6">
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }).format(sumBy(searchRes, "remNet"))}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography variant="h6">Facturé</Typography>
                    <Typography variant="h6">
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(
                        sumBy(searchRes, "rem") - sumBy(searchRes, "remNet")
                      )}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography variant="h6">Total</Typography>
                    <Typography variant="h6">
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(sumBy(searchRes, "rem"))}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Lignes/page"
          rowsPerPageOptions={[5, 10, 25, { value: 9999999, label: 'All' }]}
          component="div"
          count={detailsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div className="mb-36">
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense"
            />
            <TextField
              onChange={handleSearch}
              value={search}
              id="outlined-basic"
              label="Recherche"
              variant="outlined"
            />
          </Box>
        </div>
        {choiseDialogData && (
          <DialogChoice
            setUpdateNeeded={setUpdateNeeded}
            openStatus={choiseDialogStatus}
            setOpenStatus={setChoiseDialogStatus}
            data={choiseDialogData}
          />
        )}
      </>
    );
  } else {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};
