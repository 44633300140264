import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Backdrop, Box, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useFetchWrapper } from "../helpers/axiosWrapper";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState({
    ssn1: null,
    ssn2: null,
    ssn3: null,
    ssn4: null,
    ssn5: null,
    ssn6: null,
  });
  const fetchWrapper = useFetchWrapper();

  let history = useHistory();

  const loginUser = async () => {
    setIsLoading(true);
    fetchWrapper
      .post("/kpi/login", {
        pass: `${pin.ssn1}${pin.ssn2}${pin.ssn3}${pin.ssn4}${pin.ssn5}${pin.ssn6}`,
      })
      .then(() => {
        setIsLoading(false);
        history.push("/");
      })
      .catch(() => setIsLoading(false));
  };

  const handleFocus = (e) => {
    if (e.target.nextSibling && e.target.value) e.target.nextSibling.focus();
  };
  return (
    <Paper elevation={3}>
      <Box p={4}>
        {isLoading ? (
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <h3 className="font-semibold mb-4 -mt-4">Entrez votre PIN</h3>
            <form onSubmit={loginUser}>
              <div className="flex flex-col">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <input
                      autoFocus
                      type="password"
                      name="ssn-1"
                      id="1"
                      onKeyUp={handleFocus}
                      onChange={(e) => {
                        setPin({ ...pin, ssn1: e.target.value });
                      }}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      maxLength={1}
                      className="h-16 w-10 rounded mr-2 text-center font-semibold text-2xl bg-gray-200 dark:text-gray-50 dark:bg-gray-800"
                    />
                    <input
                      type="password"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="ssn-2"
                      id="2"
                      onKeyUp={handleFocus}
                      onChange={(e) => {
                        setPin({ ...pin, ssn2: e.target.value });
                      }}
                      maxLength={1}
                      className="h-16 w-10 rounded mr-2 text-center font-semibold text-2xl bg-gray-200 dark:text-gray-50 dark:bg-gray-800"
                    />
                    <input
                      type="password"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="ssn-3"
                      id="3"
                      onKeyUp={handleFocus}
                      onChange={(e) => {
                        setPin({ ...pin, ssn3: e.target.value });
                      }}
                      maxLength={1}
                      className="h-16 w-10 rounded mr-2 text-center font-semibold text-2xl bg-gray-200 dark:text-gray-50 dark:bg-gray-800"
                    />
                    <input
                      type="password"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="ssn-4"
                      id="4"
                      onKeyUp={handleFocus}
                      onChange={(e) => {
                        setPin({ ...pin, ssn4: e.target.value });
                      }}
                      maxLength={1}
                      className="h-16 w-10 rounded mr-2 text-center font-semibold text-2xl bg-gray-200 dark:text-gray-50 dark:bg-gray-800"
                    />
                    <input
                      type="password"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="ssn-5"
                      id="5"
                      onKeyUp={handleFocus}
                      onChange={(e) => {
                        setPin({ ...pin, ssn5: e.target.value });
                      }}
                      maxLength={1}
                      className="h-16 w-10 rounded mr-2 text-center font-semibold text-2xl bg-gray-200 dark:text-gray-50 dark:bg-gray-800"
                    />
                    <input
                      type="password"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="ssn-6"
                      id="6"
                      onKeyUp={loginUser}
                      onChange={(e) => {
                        setPin({ ...pin, ssn6: e.target.value });
                      }}
                      maxLength={1}
                      className="h-16 w-10 rounded mr-2 text-center font-semibold text-2xl bg-gray-200 dark:text-gray-50 dark:bg-gray-800"
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Box>
    </Paper>
  );
}

export default Login;
