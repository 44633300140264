import React, { useEffect, useState } from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { useFetchWrapper } from "../helpers/axiosWrapper";
import { Header } from "../components/Header";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    formControl: {
        margin: theme.spacing(2, 0),
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

export const CreateRdv = () => {
    const fetchWrapper = useFetchWrapper();
    let history = useHistory();
    const classes = useStyles();
    const [inCharge, setInCharge] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [personName, setPersonName] = React.useState([]);
    const [entreprise, setEntreprise] = useState("");
    const [commentaire, setCommentaire] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState(
        new Date()
    );

    useEffect(() => {
        getInCharge();
    }, []);


    const getInCharge = async () => {
        fetchWrapper.get(`/kpi/chasse/inCharge`)
            .then((res) => {
                const rows = res.data;
                setInCharge(rows);
                setIsLoading(false);
            })
    };
    const handleChange = (event) => {
        setPersonName(event.target.value);
    };

    const handleSubmit = async () => {
        setIsSaving(true);
        const object = {
            client: entreprise,
            inCharge: personName,
            dateRdv: new Date(selectedDate)
                .toISOString()
                .slice(0, 19)
                .replace("T", " "),
            commentaire: commentaire,
        };

        fetchWrapper.post(`/kpi/chasse/rdv`, object).then(() => {
            setIsSaving(false);
            history.push("/rdv");
        })
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    if (!isLoading && inCharge.length > 0) {
        return (
            <>
                <Header />
                <form noValidate autoComplete="off" className={classes.form}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="Entreprise"
                            label="Entreprise"
                            value={entreprise}
                            onChange={(e) => setEntreprise(e.target.value)}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-checkbox-label">
                            Abberliner
                        </InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            value={personName}
                            onChange={handleChange}
                        >
                            {inCharge.map((res) => (
                                <MenuItem key={res.id} value={res.name}>
                                    <ListItemText primary={res.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date retour contrat"
                                format="MM/dd/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="commentaire"
                            label="commentaire"
                            value={commentaire}
                            type='text'
                            onChange={(e) => setCommentaire(e.target.value)}
                        />
                    </FormControl>
                    <Box m={4}></Box>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit()}
                    >
                        {isSaving ? <CircularProgress color="inherit" /> : "Create"}
                    </Button>
                    <Box mb={10}></Box>
                </form>
            </>
        );
    } else {
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}
