import React, { useEffect, useState } from "react";
import {
  RecoilRoot,
} from 'recoil';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Login from "./pages/Login";
import { Box, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Dashboard } from "./pages/Dashboard";
import { Details } from "./pages/Details";
import Create from "./pages/Create";
import { Shortlisted } from "./pages/Shortlisted";
import { Location } from "./helpers/Location";
import { Diffs } from "./pages/Diffs";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Appbar } from "./components/Appbar";
import { useLottie } from "lottie-react";
import kpiAnimation from "./light-house.json";
import { Closed } from "./pages/Closed";
import { Rdv } from "./pages/Rdv";
import { CreateRdv } from "./pages/CreateRdv";
import { Abberliners } from "./pages/Abberliners";
import { SecHeaders } from "./helpers/SecHeaders";
import { Module404 } from "./components/Module404";
import { Notification } from "./components/Notification";
import { Settings } from "./pages/Settings";
import sad from "./assets/images/sad.png";



function App() {
  const options = {
    animationData: kpiAnimation,
    loop: true,
    autoplay: true,
  };

  useEffect(() => {
    setTimeout(function () {
      setfirstLaunch(false);
    }, 3000);
  }, []);

  const { View } = useLottie(options);
  const [firstLaunch, setfirstLaunch] = useState(true);
  const [dark, setDark] = useState(localStorage.theme);
  const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

  useEffect(() => {
    setDark(window.matchMedia("(prefers-color-scheme: dark)").matches);
  }, [systemTheme]);

  useEffect(() => {
    setDark(localStorage.theme);
  }, [dark])

  const palletType = dark === 'true' ? "light" : "dark";
  const darkTheme = createTheme({
    palette: {
      type: palletType,
    },
  });


  const [path, setPath] = useState("/");
  const [diff, setDiff] = useState(0);

  return (
    <div className="flex flex-col w-full justify-center bg-black h-screen items-center space-y-4">
      <div className="w-1/4"><img src={sad} alt="" /></div>
      <h1 className="text-white text-5xl">Il est plus là...</h1>
    </div>
    // <RecoilRoot>
    //   <SecHeaders />
    //   <Router>
    //     <ThemeProvider theme={darkTheme}>
    //       <CssBaseline />
    //       {firstLaunch ? (
    //         <Box
    //           display="flex"
    //           flexDirection="column"
    //           alignItems="center"
    //           justifyContent="center"
    //         >
    //           <Box>{View}</Box>
    //           <Box>
    //             <Typography variant="caption">
    //               <i>v1.6.6</i>
    //             </Typography>
    //           </Box>
    //         </Box>
    //       ) : (
    //         <>
    //           <Location func={setPath} />
    //           <Notification />
    //           <Box m={2}>
    //             <Appbar path={path} dark={dark} setDark={setDark} />
    //             <Switch>
    //               <Route exact path="/login">
    //                 <Login />
    //               </Route>
    //               <Route exact path="/404">
    //                 <Module404 />
    //               </Route>
    //               <ProtectedRoute
    //                 exact
    //                 path="/"
    //                 component={Dashboard}
    //                 setDiff={setDiff}
    //               />
    //               <ProtectedRoute
    //                 exact
    //                 path="/dashboard"
    //                 component={Dashboard}
    //                 setDiff={setDiff}
    //               />
    //               <ProtectedRoute
    //                 exact
    //                 path="/diff"
    //                 component={Diffs}
    //                 diff={diff}
    //               />
    //               <ProtectedRoute exact path="/create" component={Create} />
    //               <ProtectedRoute exact path="/details" component={Details} />
    //               <ProtectedRoute
    //                 exact
    //                 path="/shortlist"
    //                 component={Shortlisted}
    //               />
    //               <ProtectedRoute exact path="/closed" component={Closed} />
    //               <ProtectedRoute exact path="/rdv" component={Rdv} />
    //               <ProtectedRoute
    //                 exact
    //                 path="/createRdv"
    //                 component={CreateRdv}
    //               />
    //               <ProtectedRoute
    //                 exact
    //                 path="/abberliners"
    //                 component={Abberliners}
    //               />
    //               <ProtectedRoute
    //                 exact
    //                 path="/settings"
    //                 component={Settings}
    //               />
    //             </Switch>
    //           </Box>
    //         </>
    //       )}
    //     </ThemeProvider>
    //   </Router>
    // </RecoilRoot>
  );
}

export default App;
