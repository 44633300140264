import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useFetchWrapper } from "../helpers/axiosWrapper";
import { COLORS } from "../helpers/defs";
import { Link } from "react-router-dom";
import { unionBy, orderBy } from "lodash";


const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
    padding: theme.spacing(1) + theme.spacing(3),
    justifyContent: "center",
    display: "flex",
  },
  list: {
    marginBottom: theme.spacing(2),
    width: 250,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    right: 10,
    margin: "0 auto",
  },
  fullList: {
    width: "auto",
  },
  customBadge: {
    padding: theme.spacing(2),
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    fontWeight: "600",
    fontSize: 20,
    color: "white",
  },
  1: {
    backgroundColor: `${COLORS.green}`,
    color: "white",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  2: {
    backgroundColor: `${COLORS.yellow}`,
    color: "black",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  3: {
    backgroundColor: `${COLORS.orange}`,
    color: "white",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  4: {
    backgroundColor: `${COLORS.red}`,
    color: "white",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  5: {
    backgroundColor: `${COLORS.purple}`,
    color: "white",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  6: {
    backgroundColor: `${COLORS.blue}`,
    color: "white",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  growContainer: {
    flexGrow: 1,
  },
}));

export const Dashboard = ({ setDiff }) => {
  const fetchWrapper = useFetchWrapper();
  const [deadlineCouter, setDeadlineCouter] = useState([
    { diff: 1, cpt: 0 },
    { diff: 2, cpt: 0 },
    { diff: 3, cpt: 0 },
    { diff: 4, cpt: 0 },
  ]);
  const [shorlisted, setShorlisted] = useState([]);
  const [closed, setClosed] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getDeadlineCouter();
    getShortlisted();
    getClosed();
  }, []);

  const getDeadlineCouter = async () => {
    fetchWrapper.get(`/kpi/chasse/deadlineCouter`,)
      .then((res) => {
        const rows = res.data;
        setDeadlineCouter(
          orderBy(unionBy(rows, deadlineCouter, "diff"), "diff", "asc")
        );
        setIsLoading(false);
      })
  };

  const getShortlisted = async () => {
    fetchWrapper.get(`/kpi/chasse/shortList`,)
      .then((res) => {
        const rows = res.data;
        setShorlisted(rows);
        setIsLoading(false);
      })
  };

  const getClosed = async () => {
    fetchWrapper.get(`/kpi/chasse/closed`)
      .then((res) => {
        const rows = res.data;
        setClosed(rows);
        setIsLoading(false)
      })
  };

  const classes = useStyles();
  return (
    <div className={classes.growContainer}>
      <Grid container spacing={4}>
        {deadlineCouter.length > 0 &&
          deadlineCouter.map((res) => (
            <Grid key={res.diff} item xs={6}>
              <Link to={`/diff`} onClick={() => setDiff(res.diff)}>
                <Paper className={classes.paper} elevation={10}>
                  {!isLoading ? (
                    <Avatar className={classes[res.diff]} variant="rounded">
                      {res.cpt}
                    </Avatar>
                  ) : (
                    <Backdrop open={true}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  )}
                </Paper>
              </Link>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Link to="/shortlist">
            <Paper className={classes.paper} elevation={10}>
              <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Typography variant="body1">Shortlisted</Typography></Grid>
              <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}><Avatar className={classes[5]} variant="rounded">
                <Typography variant="h6">{shorlisted.length}</Typography>
              </Avatar></Grid>

            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="/closed">
            <Paper className={classes.paper} elevation={10}>
              <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Typography variant="body1">Closed</Typography></Grid>
              <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}><Avatar className={classes[6]} variant="rounded">
                <Typography variant="h6">{closed.length}</Typography>
              </Avatar></Grid>
            </Paper>
          </Link>
        </Grid>
      </Grid>
      <Box mb={10}></Box>
    </div>
  )
};
