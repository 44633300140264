import joy from './../assets/images/joy.webp'
import cedric from './../assets/images/cedric.webp'
import pierre from './../assets/images/pierre.webp'
import clement from './../assets/images/clement.webp'
import mikael from './../assets/images/mikael.jpeg'

export const SERVER_KEY = "9cfb6f7ddb6f61aa311c6b615d0baaa4";

export const INITIALS = {
	'Joy': 'JR',
	'Cedric': 'CP',
	'Cédric': 'CP',
	'Clément': 'CL',
	'Clement': 'CL',
	'Mikael': 'ML',
	'Mikaël': 'ML',
	'Pierre': 'PP'
}

export const ABBERLINERS_TEL = {
	'Joy': '+33612207166',
	'Cedric': '+33659401010',
	'Cédric': '+33659401010',
	'Clément': '+33608886924',
	'Clement': '+33608886924',
	'Mikael': '+33687653727',
	'Mikaël': '+33687653727',
	'Pierre': '+33618277636'
}

export const ABBERLINERS_PIC = {
	'Joy': joy,
	'Cedric': cedric,
	'Cédric': cedric,
	'Clément': clement,
	'Clement': clement,
	'Mikael': mikael,
	'Mikaël': mikael,
	'Pierre': pierre
}

export const COLORS = {
	'green': '#2a9d8f',
	'yellow': '#e9c46a',
	'orange': '#f4a261',
	'red': '#e76f51',
	'purple': '#cdb4db',
	'blue': '#a2d2ff'
}

export const CHECK_JWT = (value) => {
	console.log(value);
	console.log('<------------ CHECK ------------>');
	if(value.response.status === 401) {
		console.log('<------------ 401 ------------>');
		localStorage.setItem('jwt', '')
		// window.location.replace("/login");
	  }
}