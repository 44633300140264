import React, { useEffect, useState } from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Header } from "../components/Header";
import { useFetchWrapper } from "../helpers/axiosWrapper";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(2, 0),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const Create = () => {
  const fetchWrapper = useFetchWrapper();
  let history = useHistory();
  const [inCharge, setInCharge] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [personName, setPersonName] = React.useState([]);
  const [entreprise, setEntreprise] = useState("");
  const [poste, setPoste] = useState("");
  const [honoraire, setHonoraire] = useState(null);
  const [shortList, setShortList] = useState("");
  const [multiplier, setMultiplier] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(
    new Date()
  );
  const [buttonIsReady, setButtonIsReady] = useState(true)

  useEffect(() => {
    if (entreprise && poste && honoraire && selectedDate && personName.length > 0) {
      setButtonIsReady(false)
    } else {
      setButtonIsReady(true)
    }
  }, [entreprise, poste, honoraire, selectedDate, personName])

  useEffect(() => {
    getInCharge();
  }, []);

  const getInCharge = async () => {
    fetchWrapper.get(`/kpi/chasse/inCharge`)
      .then((res) => {
        const rows = res.data;
        setInCharge(rows);
        setIsLoading(false)
      })
  };
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleMultiplier = (value) => {
    if (value < 1) {
      setMultiplier(1);
    } else {
      setMultiplier(value)
    }
  }

  const handleSubmit = async () => {
    setIsSaving(true);
    const object = {
      client: entreprise,
      poste,
      outCharge: personName.join(),
      dateRetour: new Date(selectedDate)
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
      rem: parseInt(honoraire),
      shortList,
    };

    for (var i = 0; i < multiplier; i++) {
      await fetchWrapper.post(`/kpi/chasse`, object).then(() => {
      })
    }
    setIsSaving(false)
    history.push("/");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const maxPost = [];
  
  for (let index = 1; index < 11; index++) {
    maxPost.push(index);    
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  if (!isLoading && inCharge.length > 0) {
    return (
      <>
        <Header />
        <form noValidate autoComplete="off" className={classes.form}>
          <FormControl className={classes.formControl}>
            <TextField
              id="Entreprise cliente"
              label="Entreprise cliente"
              value={entreprise}
              onChange={(e) => setEntreprise(e.target.value)}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              required
              id="Poste"
              label="Poste"
              value={poste}
              onChange={(e) => setPoste(e.target.value)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">
              Personne(s) en charge
            </InputLabel>
            <Select
              required
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={personName}
              onChange={handleChange}
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {inCharge.map((res) => (
                <MenuItem key={res.id} value={res.name}>
                  <Checkbox checked={personName.indexOf(res.name) > -1} />
                  <ListItemText primary={res.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                maxDate={new Date()}
                margin="normal"
                id="date-picker-dialog"
                label="Date retour contrat"
                format="dd/MM/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="Honoraires"
              label="Honoraires prévues"
              value={honoraire}
              type="number"
              onChange={(e) => setHonoraire(e.target.value)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="ShortList"
              label="Shortlist"
              value={shortList}
              onChange={(e) => setShortList(e.target.value)}
              helperText="P. Nom 01/01/1970"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">
              Combien de poste identique ?
            </InputLabel>
            <Select
              required
              value={multiplier}
              onChange={(e) => handleMultiplier(e.target.value)}
            >
              {maxPost.map((res) => (
                <MenuItem key={res} value={res}>{res}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box m={4}></Box>
          <Button
            disabled={buttonIsReady}
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            {isSaving ? <CircularProgress color="inherit" /> : "Create"}
          </Button>
          <Box mb={10}></Box>
        </form>
      </>
    );
  } else {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

export default Create;
